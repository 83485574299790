import React from 'react';
import './play-controls.scss';

export type PlayControlsProps = {
    paused: boolean,
    togglePaused: () => void,
    rewind: () => void,
    fastForward: () => void,
    toStart: () => void,
    toEnd: () => void
}

export function PlayControls({paused, togglePaused, rewind, fastForward, toStart, toEnd}: PlayControlsProps) {
    return (
        <div className="play-controls">
            <button className="play-controls__btn play-controls__btn--sm" onClick={toStart}>
                <img src="assets/button-start.svg" />
            </button>
            <button className="play-controls__btn" onClick={rewind}>
                <img src="assets/button-rewind.svg" />
            </button>
            <button className="play-controls__btn play-controls__btn--lg" onClick={togglePaused}>
                {paused ?
                    <img src="assets/button-play.svg" /> :
                    <img src="assets/button-pause.svg" />
                }
            </button>
            <button className="play-controls__btn" onClick={fastForward}>
                <img src="assets/button-forward.svg" />
            </button>
            <button className="play-controls__btn play-controls__btn--sm" onClick={toEnd}>
                <img src="assets/button-end.svg" />
            </button>
        </div>
    )
}