import {EBirdMyDataSchemaWithImage} from "../views/big-year";
import jsonLogic from "json-logic-js";
import {ReactNode} from "react";
import {WrapUpStoryProps} from "../components/wrap-up-story";

export function useProcessStories() {
    type ProcessStoriesArgs = {
        year?: string | number,
        month?: string | number,
        observations?: EBirdMyDataSchemaWithImage[]
    }

    const processStories = ({year, month, observations}: ProcessStoriesArgs) => {
        const stories: WrapUpStoryProps[] = [];
        if (!observations) { return stories; }
        console.log(observations[0]);

        const firstObs = observations[0];
        stories.push({
            storyKey: 'first-bird',
            title: 'First bird of the year',
            subtitle: 'Starting strong!',
            icon: 'assets/trophy-first.svg',
            cardImage: firstObs.image,
            cardTitle: firstObs.commonName,
            cardKicker: firstObs.date,
            cardCopy: `${firstObs.location}, ${firstObs.county}, ${firstObs.stateProvince}`
        });

        const lastObs = observations[observations.length - 1];
        stories.push({
            storyKey: 'last-bird',
            title: 'Last bird of the year',
            subtitle: 'Just barely snuck this one in!',
            icon: 'assets/trophy-last.svg',
            cardImage: lastObs.image,
            cardTitle: lastObs.commonName,
            cardKicker: lastObs.date,
            cardCopy: `${lastObs.location}, ${lastObs.county}, ${lastObs.stateProvince}`
        });

        const obs50 = observations[49];
        stories.push({
            storyKey: 'bird-50',
            title: '50th bird of the year!',
            subtitle: 'Picking up steam',
            icon: 'assets/trophy-50.svg',
            cardImage: obs50.image,
            cardTitle: obs50.commonName,
            cardKicker: obs50.date,
            cardCopy: `${obs50.location}, ${obs50.county}, ${obs50.stateProvince}`
        });

        const obs100 = observations[99];
        stories.push({
            storyKey: 'bird-100',
            title: '100th bird of the year!',
            subtitle: 'Now you’re running!',
            icon: 'assets/trophy-100.svg',
            cardImage: obs100.image,
            cardTitle: obs100.commonName,
            cardKicker: obs100.date,
            cardCopy: `${obs100.location}, ${obs100.county}, ${obs100.stateProvince}`
        });

        const obs150 = observations[149];
        stories.push({
            storyKey: 'bird-150',
            title: '150th bird of the year!',
            subtitle: 'Now you’re running!',
            icon: 'assets/trophy-150.svg',
            cardImage: obs150.image,
            cardTitle: obs150.commonName,
            cardKicker: obs150.date,
            cardCopy: `${obs150.location}, ${obs150.county}, ${obs150.stateProvince}`
        });

        const obs200 = observations[199];
        stories.push({
            storyKey: 'bird-200',
            title: '200th bird of the year!',
            subtitle: 'Now you’re running!',
            icon: 'assets/trophy-200.svg',
            cardImage: obs200.image,
            cardTitle: obs200.commonName,
            cardKicker: obs200.date,
            cardCopy: `${obs200.location}, ${obs200.county}, ${obs200.stateProvince}`
        });

        return stories;
    }

    return {
        processStories
    }
}