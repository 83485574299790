import {useRef, useState} from "react";
import * as baseSpeciesImages from '../assets/data/species-images-2024.json';
import {EBirdMyDataSchemaWithImage} from "../views/big-year";

const THUMB_SIZE = 300;
const MEDIUM_SIZE = 750;

export const useGetSpeciesImages = () => {
    const [speciesImages, setSpeciesImages] = useState<any>(baseSpeciesImages);
    let workingImages: any = useRef(baseSpeciesImages);
    let speciesImageQueue: any[] = [];
    let speciesImageQueueInterval: any;

    const getSpeciesImages = (markers: any) => {
        workingImages.current = speciesImages.length ? speciesImages : baseSpeciesImages;
        speciesImageQueue = [];

        for (let feature of markers?.features) {
            const props = feature.properties;
            if (!Object.prototype.hasOwnProperty.call(workingImages.current, props.taxonomicOrder)) {
                speciesImageQueue.push(props);
            }
        }

        void processSpeciesImageQueue();
        if (speciesImageQueue.length) {
            speciesImageQueueInterval = setInterval(processSpeciesImageQueue.bind(this), 1000);
        }
    }

    const processSpeciesImageQueue = async () => {
        if (!speciesImageQueue?.length) {
            clearInterval(speciesImageQueueInterval);
            // console.log('Updated species image list');
            console.log(workingImages.current.length);
            setSpeciesImages(workingImages.current);
            return;
        }

        const nextQueueItem = speciesImageQueue.shift();
        console.log('Next queue item is', nextQueueItem);

        try {
            // Remove subspecies and other modifiers like "(feral)" and "[cheriway group]" from the scientific name before trying to look up the image
            let speciesName = nextQueueItem.scientificName.replace(/[[(].+[\])]$/, '');
            const splitName = speciesName.split(' ');
            if (splitName.length > 2) {
                speciesName = `${splitName[0]} ${splitName[1]}`;
            }

            console.log(`Attempting to find species image for ${speciesName}`);

            const speciesImageQuery = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=pageimages&titles=${speciesName}&piprop=thumbnail%7Cname&pithumbsize=400&pilimit=50&redirects&origin=*`
            const response = await fetch(speciesImageQuery);
            const body = await response.json();
            const page: any = Object.values(body.query.pages)[0];

            const thumbUrl = page.thumbnail.source;
            const mediumUrl = page.thumbnail.source.replace(`${THUMB_SIZE}px`, `${MEDIUM_SIZE}px`);

            workingImages.current[nextQueueItem.taxonomicOrder] = {
                thumb: thumbUrl,
                medium: mediumUrl
            };

            console.log(`Assigning image ${thumbUrl} to taxon ID ${nextQueueItem.taxonomicOrder}`);
        } catch(_e) {
            console.log(`Unable to load image for ${nextQueueItem.scientificName}`);
        }
    }

    return {
        speciesImages,
        getSpeciesImages
    }
}