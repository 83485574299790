import {ObservationCard} from "./observation-card";
import {ReactNode, useContext, useEffect, useState} from "react";
import {DateTime} from 'luxon';
import {AppContext} from "../App";
import {EBirdTaxonomyEntry} from "../helpers/useEBirdTaxonomy";

export type InfoboxObservationCardProps = {
    currentMarker: any,
    currentIndex: number,
    total: number,
    year?: string|number,
    month?: string|number
}

export function InfoboxSingleObservation({currentMarker, currentIndex, total, year, month}: InfoboxObservationCardProps) {
    const [intro, setIntro] = useState<ReactNode>();
    const [title, setTitle] = useState<ReactNode>();
    const [image, setImage] = useState<string>();
    const [kicker, setKicker] = useState<string>();
    const [icon, setIcon] = useState<ReactNode>();
    const [copy, setCopy] = useState<ReactNode>();

    const appContext = useContext(AppContext);

    useEffect(() => {
        if (!currentMarker?.properties) return;
        const props = currentMarker.properties;

        const taxonomyResult: EBirdTaxonomyEntry|undefined = appContext.lookupByTaxonomicOrder(props.taxonomicOrder);
        console.log(taxonomyResult);

        if (taxonomyResult) {
            setTitle((
                <a href={`https://ebird.org/species/${taxonomyResult.speciesCode}`} target="_blank">{props.species}</a>
            ));
        } else {
            setTitle(props.species);
        }

        setImage(props.image);

        const date = DateTime.fromISO(props.date);
        const dateLong = date.toFormat('cccc, LLLL d, y');
        const dateShort = date.toFormat('LLLL d');
        setCopy(
            <>
                {dateLong}<br />
                {props.locationName}<br />
                {props.locationName2}<br />
                Count: {props.count}
            </>
        )

        if (currentIndex + 1 && total) {
            setKicker(`${currentIndex + 1}/${total}`);
        }

        const isYearMode = !isNaN(parseInt(year as string)) && !month;

        /*if (currentIndex === 0 && isYearMode) {
            setIntro(`Starting ${year} strong on ${dateShort}`);
            setIcon(<img src="assets/trophy-first.svg" title="First bird of the year!" />);
        } else if (currentIndex === (total - 1) && isYearMode) {
            setIntro(`Just barely squeaked this one in on ${dateShort}!`);
            setIcon(<img src="assets/trophy-last.svg" title="Last bird of the year!" />);
        } else*/
        if (props.isLifer) {
            setIntro(undefined);
            setIcon(<img src="assets/lifer.svg" title="Life bird!" />);
        } else if (props.isFirstOfYear) {
            // setIntro(`First ${props.species} of ${props.observedYear}`);
            setIntro(undefined);
            setIcon(<img src="assets/foy.svg" title="First of year bird" />);
        } else {
            setIntro(undefined);
            setIcon(undefined);
        }
    }, [currentMarker, total, currentIndex]);

    return (
        <>
            {intro ?
                <h2 className="infobox__title">{intro}</h2>
                : null
            }
            
            <ObservationCard
                image={image}
                kicker={kicker}
                title={title}
                copy={copy}
                icon={icon}
            />
        </>
    )
}