import React, {ReactNode, useEffect, useState} from 'react';
import {ObservationCard} from "./observation-card";
import {DateTime} from "luxon";

export type InfoboxSpeciesLocationDetailProps = {
    taxonomicOrder: number,
    observations?: any[]
}

export function InfoboxSpeciesLocationDetail({taxonomicOrder, observations}: InfoboxSpeciesLocationDetailProps) {

    const [title, setTitle] = useState('');
    const [image, setImage] = useState();
    const [copy, setCopy] = useState<ReactNode>();

    const formatDate = (date: string) => {
        const dateTime = DateTime.fromISO(date);
        return dateTime.toFormat('cccc, LLLL d, y');
    }

    const formatTime = (date: string) => {
        const dateTime = DateTime.fromISO(date);
        return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
    }

    const formatObservations = (observations: any[]) => {
        return (
            <>
                {observations?.map((obs: any) => (
                    <>
                        <div className="observation-card__kicker">{formatDate(obs.properties.date)}</div>
                        <div className="observation-card__copy mb-3">
                            {formatTime(obs.properties.date)},
                            Count: {obs.properties.count}
                        </div>
                    </>
                ))}
            </>
        )
    }

    useEffect(() => {
        console.log(observations);

        if (observations?.length) {
            const species = observations[0].properties.species;
            const location = observations[0].properties.locationName;
            setTitle(`Observations for ${species} at ${location}`);
            setImage(observations[0].properties.image ?? null);
            setCopy(formatObservations(observations));

        }
    }, [observations]);

    return (
        <>
            {title ?
                <h2 className="infobox__title">
                    {title}
                </h2>
                : null
            }

            <div className="infobox__body">
                <ObservationCard
                    image={image}
                    // kicker={formatDate(obs.properties.date)}
                    // title={}
                    copy={copy}
                    // icon={}
                />
            </div>
        </>
    )
}