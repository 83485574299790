import React, {ChangeEvent, useEffect, useState} from 'react';
import Card from "../components/card";
import Button from "../components/button";
import {EBirdMyDataSchema} from "ebird-mydata-reader";

export type SelectTimePeriodProps = {
    years: number[]|string[],
    months: number[]|string[],
    onSetYear: React.Dispatch<number|string>,
    onSetMonth: React.Dispatch<number>,
    onSetTimePeriod: (year: string, month: string) => void,
    onShowWrapUp: () => void,
    onShowChecklist: () => void,
    lifeList: EBirdMyDataSchema[]
}

export type SelectMonthOption = {
    value: number,
    label: string
}

export default function SelectTimePeriod({years, months, onSetYear, onSetMonth, onSetTimePeriod, lifeList, onShowWrapUp, onShowChecklist}: SelectTimePeriodProps) {
    const baseMonths = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ];
    const [year, setYear] = useState<any>();
    const [month, setMonth] = useState<any>();
    const [filteredMonths, setFilteredMonths] = useState<SelectMonthOption[]>(baseMonths);
    const [subtitle, setSubtitle] = useState<string>();

    const onMonthChange = (e: ChangeEvent) => {
        setMonth((e.target as HTMLInputElement).value);
    }

    const onYearChange = (e: ChangeEvent) => {
        const year = (e.target as HTMLInputElement).value;
        setYear(year);
        onSetYear(year);
    }

    const handleSetTimePeriod = () => {
        onSetYear(year);
        onSetMonth(month);
        onSetTimePeriod(year, month);
    }

    const handleShowWrapUp = () => {
        handleSetTimePeriod();
        onShowWrapUp();
    }

    const handleShowChecklist = () => {
        onShowChecklist();
    }

    useEffect(() => {
        console.log(lifeList);
        console.log(years[0]);
        console.log(years[years.length - 1]);
        let numYears = (years[0] as number) - (years[years.length - 1] as number);
        numYears = Math.max(numYears, 1);
        setSubtitle(`Your life list contains ${lifeList?.length} species over ${numYears} ${numYears !== 1 ? 'years' : 'year'}`);
    }, [lifeList]);

    useEffect(() => {
        if (!months?.length) return;

        const filteredMonths = baseMonths.filter((month: SelectMonthOption) => {
            const val: number = month.value;
            return (months as number[]).indexOf(val) > -1;
        });

        setFilteredMonths(filteredMonths);
    }, [months]);

    return (
        <Card title="Wow, you've got some history here!" subtitle={subtitle}>
            <h3 className="h4 text-primary text-center">Ready to start exploring?</h3>
            <p className="text-center">Show me birds from:</p>

            <p className="text-center">
                <select className="form-select" onChange={onYearChange} value={year}>
                    <option value="null">Select a Year</option>
                    <option value="life">Life List</option>
                    {years.map(year => (
                        <option value={year} key={year}>{year}</option>
                    ))}
                </select>
            </p>

            <p className="text-center">
                <select className="form-select" onChange={onMonthChange} value={month}>
                    <option value="null">All Months</option>
                    {filteredMonths.map(month => (
                        <option value={month.value} key={month.label}>{month.label}</option>
                    ))}
                </select>
            </p>

            <p className="text-center">
                <Button btnClass="primary" onClick={handleSetTimePeriod}>Start Exploring!</Button>
                <Button btnClass="secondary ms-2" onClick={handleShowChecklist}>Show Checklist</Button>
                {/*<Button btnClass="secondary ms-2" onClick={handleShowWrapUp}>Show Wrap-Up</Button>*/}
            </p>
        </Card>
    )
}