import React, {ReactNode} from 'react';
import Infobox from "./infobox";
import {ObservationCard} from "./observation-card";
import './wrap-up-block.scss';

export type WrapUpStoryProps = {
    title: string,
    subtitle?: string,
    icon?: string,
    storyKey: string,
    cardImage?: string,
    cardTitle?: string,
    cardKicker?: string,
    cardCopy?: string | ReactNode
}

export const WrapUpStory = ({title, subtitle, icon, storyKey, cardImage, cardTitle, cardKicker, cardCopy}: WrapUpStoryProps) => {
    return (
        <div className="wrap-up-block">
            <div className="wrap-up-block__header">
                {icon ?
                    <img className="wrap-up-block__icon" src={icon} />
                    : null
                }
                <div>
                    <h2 className="wrap-up-block__title">{title}</h2>
                    {subtitle ?
                        <p>{subtitle}</p>
                        : null
                    }
                </div>
            </div>
            <div className="wrap-up-block__body">
                <Infobox type="inline">
                    <ObservationCard
                        key={storyKey}
                        image={cardImage}
                        title={cardTitle}
                        kicker={cardKicker}
                        copy={cardCopy}
                    />
                </Infobox>
            </div>

        </div>
    )
}