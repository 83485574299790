import React from 'react';
import './header.scss';

export type HeaderProps = {
    showStartOver: boolean,
    onStartOver: () => void
}

export default function Header({
    showStartOver,
    onStartOver,
}: HeaderProps) {
    return (
        <div className="app-header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col app-header__main align-items-center d-flex">
                        <img src="/assets/birdmap-logo.svg" alt="BirdMap Explorer" className="app-header__logo me-3" />
                    </div>
                    <div className="col app-header__actions">
                        {showStartOver ?
                            <a href="#" className="text-btn" onClick={onStartOver}>
                                <img src="assets/restart.svg" className="text-btn__icon text-btn__icon-left" />
                                Start Over
                            </a>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}