import React, {MouseEvent} from 'react';

export type ButtonProps = {
    btnClass?: string,
    children: React.ReactNode,
    onClick?: (e: MouseEvent) => void
}

export default function Button({btnClass, children, onClick}: ButtonProps) {
    return (
        <button className={`btn btn-${btnClass}`} onClick={onClick}>
            {children}
        </button>
    )
}