import React, {ReactNode} from 'react';
import './observation-card.scss';

export type ObservationCardProps = {
    kicker?: string|ReactNode,
    title?: string|ReactNode,
    copy?: string|ReactNode,
    image?: string
    icon?: ReactNode
}

export function ObservationCard({kicker, title, copy, image, icon}: ObservationCardProps) {
    return (
        <div className="observation-card">
            {image ?
                <div className="observation-card__img">
                    <img src={image} />
                </div>
                : null
            }
            {(kicker || title || copy) ?
                <div className="observation-card__body">
                    {kicker ?
                        <div className="observation-card__kicker">{kicker}</div>
                        : null
                    }
                    {title ?
                        <div className="observation-card__title">{title}</div>
                        : null
                    }
                    {copy ?
                        <div className="observation-card__copy">{copy}</div>
                        : null
                    }
                </div> : null
            }
            {icon ?
                <div className="observation-card__icon">{icon}</div>
                : null
            }
        </div>
    )
}