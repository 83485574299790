import React, {ChangeEvent, useEffect, useState} from 'react';
import './map-mode-control.scss';
import {MapMode, MapModeType} from "../App";
import Button from "./button";
import Select, {ActionMeta} from "react-select";
import {SelectMonthOption} from "../views/select-time-period";

;

export type MapModeControlProps = {
    mode: MapModeType,
    setMode: React.Dispatch<MapModeType>,
    years: number[]|string[],
    appYear?: number|string,
    appMonth?: number|string,
    onSetYear: React.Dispatch<number|string>,
    months: number[]|string[],
    onSetMonth: React.Dispatch<number|undefined>
    onSetTimePeriod: (year: string, month: string) => void,
    observationsBySpecies: any[],
    onSetSpecies: React.Dispatch<number>
    onStartBigYear: () => void
    onShowWrapUp: () => void
}

export function MapModeControl({
    mode,
    setMode,
    years,
    appYear,
    onSetYear,
    months,
    appMonth,
    onSetMonth,
    onSetTimePeriod,
    observationsBySpecies,
    onSetSpecies,
    onStartBigYear,
    onShowWrapUp
}: MapModeControlProps) {
    const baseMonths = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ];
    const [year, setYear] = useState<any>(appYear);
    const [month, setMonth] = useState<any>(appMonth);
    const [speciesList, setSpeciesList] = useState<any>([]);
    const [locationList, setLocationList] = useState<any>([]);
    const [filteredMonths, setFilteredMonths] = useState<SelectMonthOption[]>(baseMonths);

    const setMapMode = (e: ChangeEvent) => {
        const target = (e.target as HTMLInputElement);
        setMode(target.value as MapModeType);
    }

    useEffect(() => {
        if (appYear) {
            setYear(appYear);
        }

        if (appMonth) {
            setMonth(appMonth);
        }
    }, [appMonth, appYear]);

    useEffect(() => {
        if (!months?.length) return;

        const filteredMonths = baseMonths.filter((month: SelectMonthOption) => {
            const val: number = month.value;
            return (months as number[]).indexOf(val) > -1;
        });

        if ((months as number[]).indexOf(parseInt(month as string)) < 0) {
            setMonth(undefined);
            onSetMonth(undefined);
        }

        setFilteredMonths(filteredMonths);
    }, [months]);

    useEffect(() => {
        if (!observationsBySpecies?.length) return;

        setSpeciesList(observationsBySpecies.map(el => {
            return {
                label: el.commonName,
                value: el.taxonomicOrder
            }
        }))
    }, [observationsBySpecies]);

    const onYearChange = (e: ChangeEvent) => {
        const year = (e.target as HTMLInputElement).value;
        setYear(year);
        onSetYear(year);
    }

    const onMonthChange = (e: ChangeEvent) => {
        setMonth((e.target as HTMLInputElement).value);
    }

    const setTimePeriod = () => {
        onSetYear(year);
        onSetMonth(month);
        onSetTimePeriod(year, month);
    }

    const setSpecies = (option: any, actionMeta: ActionMeta<any>) => {
        onSetSpecies(option.value);
    }

    const setLocation = (option: any, actionMeta: ActionMeta<any>) => {
        console.log(option);
    }

    const selectStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            width: 400
        })
    }

    const [showBigYear, setShowBigYear] = useState(false);

    const startBigYear = () => {
        onStartBigYear();
    }

    useEffect(() => {
        console.log(parseInt(year as string));

        if (parseInt(year as string) !== NaN) {
            setShowBigYear(true);
        } else {
            setShowBigYear(false);
        }
    }, [year]);

    return (
        <div className="map-mode-control">
            <select name="" className="form-select form-select-sm w-auto" onChange={setMapMode} value={mode}>
                {/*<option value="location">Location Explorer</option>*/}
                <option value="year">Year Explorer</option>
                <option value="species">Species Explorer</option>
            </select>

            {
                mode === MapMode.Year ?
                    <>
                        <select className="form-select form-select-sm w-auto mb-0 me-3" onChange={onYearChange} value={year}>
                            <option value="null">Select a Year</option>
                            <option value="life">Life List</option>
                            {years.map(year => (
                                <option value={year} key={year}>{year}</option>
                            ))}
                        </select>

                        <select className="form-select form-select-sm w-auto mb-0 me-3" onChange={onMonthChange} value={month}>
                            <option value="">All Months</option>
                            {filteredMonths.map(month => (
                                <option value={month.value} key={month.label}>{month.label}</option>
                            ))}
                        </select>

                        <Button btnClass="primary text-nowrap" onClick={setTimePeriod}>Go</Button>
                        {/*<Button btnClass="secondary ms-2" onClick={onShowWrapUp}>Show Wrap-Up</Button>*/}
                    </> : null
            }

            {
                mode === MapMode.Species ?
                    <>
                        <div className="col-3">
                            <Select
                                className="form-select-sm"
                                options={speciesList}
                                onChange={setSpecies}
                            />
                        </div>
                    </>
                : null
            }

            <div className="map-mode-control__actions">
                {showBigYear ?
                    <Button btnClass="primary text-no-wrap btn-sm" onClick={startBigYear}>Show &ldquo;The Big Year&rdquo; Thing</Button>
                    : null}
            </div>
        </div>
    )
}