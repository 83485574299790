import React, {ReactNode} from 'react';
import {EBirdChecklistByFamily} from "ebird-mydata-reader";
import './family-card.scss';

export type FamilyCardProps = {
    onClick?: (family: EBirdChecklistByFamily) => void
    subject: any;
    seen?: boolean;
    img?: string;
    alt?: string;
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    description?: string | ReactNode;
}

export function ChecklistCard({subject, onClick, seen = false, img, alt = '', title, subtitle, description}: FamilyCardProps) {
    const getClassName = () => {
        const classes = ['family-card'];
        classes.push(seen ? 'family-card--seen' : 'family-card--unseen');

        return classes.join(' ');
    }

    return (
        <div className={getClassName()}>
            <a href="#" className="family-card__link" onClick={() => onClick && onClick(subject)}>
                <span className="family-card__img-wrap">
                    <img src={img} alt={alt} className="family-card__img"/>
                </span>
                <div className="family-card__title">{title}</div>
                {subtitle && <div className="family-card__stats">{subtitle}</div>}
                {description && <div className="family-card__desc">{description}</div>}
            </a>
        </div>
    )
}