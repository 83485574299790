import {EBirdMyDataSchema} from "ebird-mydata-reader";
import {DateTime} from "luxon";

export const generateMarkers = (filteredObservations: EBirdMyDataSchema[]) => {
    const markers: any = {
        type: "FeatureCollection",
        features: []
    };

    let i = 0;
    for (const observation of filteredObservations) {
        const observationDate = new Date(`${observation.date} ${observation.time}`);

        const marker: any = {
            type: "Feature",
            id: i++,
            properties: {
                species: observation.commonName,
                date: observationDate.toJSON(),
                dateMs: observationDate.getTime(),
                locationId: observation.locationId,
                locationName: observation.location,
                // "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Mourning_Dove_2006.jpg/267px-Mourning_Dove_2006.jpg",
                locationName2: `${observation.county ?? ''} ${observation.stateProvince}`,
                scientificName: observation.scientificName,
                taxonomicOrder: observation.taxonomicOrder,
                mlCatalogNumbers: observation.mlCatalogNumbers,
                count: observation.count,
                isLifer: observation.isLifer ?? false,
                isFirstOfYear: observation.isFirstOfYear ?? false,
                observedYear: observation.observedYear
            },
            geometry: {
                type: "Point",
                coordinates: [
                    observation.longitude,
                    observation.latitude
                ]
            }
        }
        markers.features.push(marker);
    }

    return markers;
}

export const formatDate = (date: string) => {
    const dateTime = DateTime.fromISO(date);
    return dateTime.toFormat('cccc, LLLL d, y');
}