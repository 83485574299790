import React from 'react';
import Card from '../components/card';
import Button from '../components/button';

export type WelcomeProps = {
    onRequestData: (e: React.MouseEvent) => void
}

export default function Welcome({onRequestData}: WelcomeProps) {
    return (
        <Card title="Welcome to BirdMap Explorer! Let's get started!">
            <p>BirdMap Explorer lets you visualize your birding adventures on a map of the world. You can view your life list, your year list, or all the places you've ever gone birding, based on your eBird sightings.</p>
            <p>To get started, you'll need to download your eBird data as a ZIP file. Click the button below to go to eBird, then click the "Submit" button. Within a few minutes, you should receive an email with a link to your data file.</p>
            <p className="text-center mb-0">
                <Button btnClass="primary" onClick={onRequestData}>Request my eBird Data</Button>
            </p>
            <p className="text-center mt-4">
                <a href="https://ebird.org/about" target="_blank" className="question-link text-brand-red">
                    Huh? What&rsquo;s eBird?
                </a>
            </p>
        </Card>
    )
}