export const speciesGroupOverrides: {[key: string]: string} = {
  "Chachalacas, Guans, and Curassows": "Guans",
  "Grouse, Quail, and Allies": "Grouse and Quails",
  "Rails, Gallinules, and Allies": "Rails",
  "Gulls, Terns, and Skimmers": "Gulls and Terns",
  "Petrels, Shearwaters, and Diving-Petrels": "Petrels and Shearwaters",
  "Frigatebirds, Boobies, and Gannets":  "Gannets",
  "Herons, Ibis, and Allies":  "Herons and Egrets",
  "Vultures, Hawks, and Allies": "Raptors",
  "Bee-eaters, Rollers, and Allies":  "Rollers",
  "Becards, Tityras, and Allies":  "Tityras",
  "Sharpbill, Royal Flycatcher, and Allies":  "Flycatchers",
  "Tyrant Flycatchers: Elaenias, Tyrannulets, and Allies": "Flycatchers",
  "Tyrant Flycatchers: Pewees, Kingbirds, and Allies": "Flycatchers",
  "Jays, Magpies, Crows, and Ravens": "Crows and Jays",
  "Tits, Chickadees, and Titmice": "Chickadees and Titmice",
  "Penduline-Tits and Verdin": "Verdin",
  "Catbirds, Mockingbirds, and Thrashers": "Mockingbirds and Thrashers",
  "Finches, Euphonias, and Allies": "Finches",
  "Wood-Warblers": "Warblers",
  "Cardinals, Grosbeaks, and Allies": "Cardinals and Grosbeaks",
  "Tanagers and Allies": "Tanagers",
}