import React, {useEffect, useState} from 'react';
import {EBirdChecklistByFamily, EBirdChecklistBySpecies} from "ebird-mydata-reader";
import Card, {CardVariant} from "../components/card";
import {ChecklistCard} from "../components/checklist-card";
import './checklist.scss';
import {formatDate} from "../helpers/helpers";
import Button from "../components/button";

export type ChecklistProps = {
    checklist: EBirdChecklistByFamily[]
}

export default function Checklist({checklist}: ChecklistProps) {
    const getSubtitle = () => {
        return selectedFamily ? `Family: ${selectedFamily.familyName} — ${selectedFamily.seenCount} / ${selectedFamily.totalCount} seen` : 'All Families'
    }

    const [selectedFamily, setSelectedFamily] = useState<EBirdChecklistByFamily|null>(null);

    const handleChangeFamily = (family: EBirdChecklistByFamily) => {
        setSelectedFamily(family);
    }

    const getSpeciesTitle = (species: EBirdChecklistBySpecies) => {
        return <a href={'https://ebird.org/species/' + species.speciesCode} target="_blank">{species.commonName}</a>;
    }

    const getSpeciesSubtitle = (species: EBirdChecklistBySpecies) => {
        return <>{species.scientificName}</>;
    }

    const getSpeciesDescription = (species: EBirdChecklistBySpecies) => {
        return <>
            {species.seen ?
                <><strong>Seen</strong> {formatDate(species.firstObservation.date)},{' '}
                    {species.firstObservation.location},{' '}{species.firstObservation.county},{' '}
                    {species.firstObservation.stateProvince}
                </> :
                <><strong>Not Seen</strong>
                </>}
        </>;
    }

    return (
        <>
            <Card title="Your birding checklist" subtitle={getSubtitle()} variant={CardVariant.WIDE}>
                {selectedFamily === null ?
                    <>
                        <ul className="checklist-list">
                        {checklist.map(family => (
                            <li className="checklist-list__item">
                                <ChecklistCard
                                    subject={family}
                                    seen={family.seen}
                                    key={family.familyName}
                                    title={family.familyName}
                                    alt={family.familyName}
                                    subtitle={family.seen ? <>
                                        <strong>{family.seenCount} </strong> / {family.totalCount} seen
                                        </> :
                                        <>
                                            <>{family.seenCount} / {family.totalCount} seen</>
                                        </>
                                    }
                                    img={family.speciesList[0]?.image?.thumb || 'assets/crow-solid.svg'}
                                    onClick={handleChangeFamily}
                                />
                            </li>
                        ))}
                        </ul>
                    </> :
                    <>
                        <Button btnClass="primary" onClick={() => setSelectedFamily(null)}>Back to All Families</Button>
                        <ul className="checklist-list">
                            {selectedFamily.speciesList.map(species => (
                                <li className="checklist-list__item">
                                <ChecklistCard subject={species}
                                   seen={species.seen}
                                   key={species.scientificName}
                                   title={getSpeciesTitle(species)}
                                   subtitle={getSpeciesSubtitle(species)}
                                   description={getSpeciesDescription(species)}
                                   img={species.image?.thumb || 'assets/crow-solid.svg'}
                                   alt={species.commonName}
                                />
                                </li>
                            ))}
                        </ul>
                    </>
                }
            </Card>
        </>
    );
}