import {useEffect, useRef} from "react";
import * as Papa from 'papaparse';
import {speciesGroupOverrides} from "./species-group-overrides";

export type EBirdTaxonomyEntry = {
    taxonomicOrder: number,
    category: string,
    speciesCode: string,
    commonName: string,
    scientificName: string,
    order: string,
    family: string,
    speciesGroup?: string,
    reportAsCod?: string
}

const columnTransforms: any = {
    TAXON_ORDER: 'taxonomicOrder',
    CATEGORY: 'category',
    SPECIES_CODE: 'speciesCode',
    PRIMARY_COM_NAME: 'commonName',
    SCI_NAME: 'scientificName',
    ORDER1: 'order',
    FAMILY: 'family',
    SPECIES_GROUP: 'speciesGroup',
    REPORT_AS: 'reportAsCode'
}

const headerTransformFunction = (col: string) => {
    if (columnTransforms.hasOwnProperty(col)) {
        return columnTransforms[col];
    }

    return col;
}

const options = {
    header: true,
    transformHeader: headerTransformFunction,
    dynamicTyping: true
}

export function useEBirdTaxonomy() {
    const taxonomyData = useRef<EBirdTaxonomyEntry[]>();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch('./assets/ebird_taxonomy_v2024.csv');
                const csvTaxonomy = await response.text();
                const jsonTaxonomy = Papa.parse(csvTaxonomy, options);
                // console.log(jsonTaxonomy);
                taxonomyData.current = jsonTaxonomy.data as EBirdTaxonomyEntry[];

                for (let row of taxonomyData.current) {
                    if (row.speciesGroup && speciesGroupOverrides.hasOwnProperty(row.speciesGroup)) {
                        row.speciesGroup = speciesGroupOverrides[row.speciesGroup];
                    }
                }
            } catch(e) {
                console.log('Unable to load eBird taxonomy data');
            }
        }

        fetchData();
    }, []);

    const lookupByTaxonomicOrder = (taxonomicOrder: number): EBirdTaxonomyEntry|undefined => {
        if (taxonomyData.current) {
            const found = taxonomyData.current.find((el: EBirdTaxonomyEntry) => el.taxonomicOrder === taxonomicOrder);
            return found ?? undefined;
        } else {
            console.log('eBird taxonomy data is unavailable');
            return undefined;
        }
    }

    return {
        lookupByTaxonomicOrder
    }
}