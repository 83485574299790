import React, {ReactNode} from 'react';
import './infobox.scss';

export type InfoboxProps = {
    children: ReactNode
    type?: string
}

export default function Infobox({type, children}: InfoboxProps) {
    return (
        <div className={type === 'inline' ? 'infobox infobox--inline' : 'infobox'}>
            {children}
        </div>
    )
}