import React, {ReactNode, useEffect, useState} from 'react';
import {ObservationCard} from "../components/observation-card";
import Infobox from "../components/infobox";
import {WrapUpStory, WrapUpStoryProps} from "../components/wrap-up-story";
import {EBirdMyDataSchemaWithImage} from "./big-year";
import {useProcessStories} from "../helpers/useProcessStories";

const wrapUpCategories = [
    {/* First bird of the year*/},
    {/* Last bird of the year*/},
    {/* 25th bird of the year*/},
    {/* 50th bird of the year*/},
    {/* 75th bird of the year*/},
    {/* 100th bird of the year*/},
    {/* 150th bird of the year*/},
    {/* 200th bird of the year*/},
    {/* 250th bird of the year*/},
    {/* 300th bird of the year*/},
    {/* 350th bird of the year*/},
    {/* 400th bird of the year*/},
    {/* 500th bird of the year*/},
    {/* 600th bird of the year*/},
    {/* 700th bird of the year*/},
    {/* 800th bird of the year*/},
    {/* 900th bird of the year*/},
    {/* 1000th bird of the year*/},
    {/* Most seen bird */},
    {/* Most productive hotspot */},
    {/* Biggest day */},
    {/* Total number of checklists */},
    {/* Total time spent birding */},
    {/* Most seen family (species) */},
    {/* Most seen family (individuals) */},
    {/* Total number of individuals seen */},
    {/* Most productive hour of the day (species) */},
]

export type WrapUpProps = {
    observations: EBirdMyDataSchemaWithImage[],
    year?: string | number,
    month?: string | number,
}



export default function WrapUp({observations, year, month}: WrapUpProps) {
    const [stories, setStories] = useState<WrapUpStoryProps[]>([]);
    const {processStories} = useProcessStories();

    useEffect(() => {
        const stories = processStories({year, month, observations});
        setStories(stories);
    }, [observations, year, month]);

    return (
        <div className="my-5">
            <div className="card">
                <h1 className="card__title">
                    Here's your 2020 wrap-up!
                </h1>
                <p className="card__subtitle">You had quite a year and racked up over 2500 birds from 260 different species! Here are some of the highlights.</p>
            </div>

            {stories?.map(storyProps => (
                <WrapUpStory {...storyProps} />
            ))}
        </div>
    )
}