import React, {ChangeEvent, useEffect, useState} from 'react';
import Button from "../components/button";
import Card from "../components/card";
import * as localforage from "localforage";
import {loadDataFile, parseData} from "ebird-mydata-reader/dist/cjs/ebird-mydata-reader";
import {DateTime} from 'luxon';

export type UploadDataProps = {
    onSetData: React.Dispatch<any>
    onSetYears: React.Dispatch<any>
    onResetDataRequest: (e: React.MouseEvent) => void,
}

export default function UploadData({onSetData, onResetDataRequest, onSetYears}: UploadDataProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [storedEbirdData, setStoredEbirdData] = useState<any>();
    const [lastUpdated, setLastUpdated] = useState<string>('');

    useEffect(() => {
        async function loadData() {
            const storedData: any = await localforage.getItem('storedEbirdData');
            // console.log(storedData?.date);

            if (storedData) {
                setStoredEbirdData(storedData);
            }
        }

        void loadData();
    }, []);

    useEffect(() => {
        if (storedEbirdData?.date) {
            const dt = DateTime.fromJSDate(storedEbirdData.date);
            setLastUpdated(dt.toLocaleString(DateTime.DATETIME_FULL));
        }
    }, [storedEbirdData]);

    const onFileChange = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        if (target.files?.length) {
            const dataFile = target.files[0];
            const fr = new FileReader();

            fr.addEventListener('load', async (e) => {
                setIsLoading(true);

                const csvData = await loadDataFile(e.target?.result as ArrayBuffer);
                const jsonData = parseData(csvData);

                processData(jsonData);
            });

            fr.readAsArrayBuffer(dataFile);
        }
    };

    const useStoredData = () => {
        processData(storedEbirdData.data);
    };

    const resetStoredData = () => {
        setStoredEbirdData(null);
        localforage.removeItem('storedEbirdData');
    }

    const processData = (jsonData: any) => {
        localforage.setItem('storedEbirdData', {
            date: new Date(),
            data: jsonData
        });
        onSetData(jsonData);

        let years = [];
        for (let row of jsonData) {
            if (!row.date) continue;

            const year = parseInt(row.date.split('-')[0]);

            if (years.indexOf(year) < 0) {
                years.push(year);
            }
        }

        years = years.sort().reverse();
        onSetYears(years);
    }

    return (
        <Card
            title={
                storedEbirdData ?
                    'Hey, looks like you\'ve been here before!' :
                    'Welcome to BirdMap Explorer! Let\'s get started!'
            }
        >
            {storedEbirdData ?
                <>
                    <p>Welcome back! Ready to keep exploring with your eBird data (last updated on <strong>{lastUpdated}</strong>)?</p>
                    <div className="container">
                        <div className="col-8 d-grid gap-3 mt-2 mb-2 m-auto">
                            <Button btnClass="primary d-block" onClick={useStoredData}>Keep Exploring</Button>
                            <Button btnClass="secondary d-block" onClick={resetStoredData}>Upload a new Data File</Button>
                        </div>
                    </div>
                </> :
                <>
                    <p>Click the button below to select your eBird data ZIP file, or drag and drop the ZIP file here to load and process your eBird data.</p>
                    <p className="text-center">
                        <input type="file"
                               className="form-control"
                               name="dataFileUpload"
                               id="dataFileUpload"
                               placeholder="Upload Data File"
                               onChange={onFileChange} />
                    </p>

                    <p className="text-center">
                        <Button btnClass="link" onClick={onResetDataRequest}>Show me that download link again?</Button>
                    </p>
                </>
            }
        </Card>
    )
}