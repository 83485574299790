import React, {ReactNode, useEffect, useState} from 'react';
import {ObservationCard} from "./observation-card";
import {DateTime} from "luxon";
import {EBirdMyDataSchema} from "ebird-mydata-reader";
import {formatDate} from "../helpers/helpers";

export type InfoboxLocationDetailProps = {
    observations?: any[],
    year?: number | string
    month?: number | string
}

export function InfoboxLocationDetail({observations, year, month}: InfoboxLocationDetailProps) {

    const [title, setTitle] = useState('');

    const getIcon = (observation: EBirdMyDataSchema) => {
        return observation.isLifer ? (
            <img src="assets/lifer.svg" title="Life bird!" />
        ) : null;
    }

    useEffect(() => {
        if (observations?.length) {
            const location = observations[0].location;
            let title = `Species for ${location}`;
            if (year && !isNaN(parseInt(year as string))) {
                if (month && year !== 'life') {
                    const date = new Date(`${year}-${month}`);
                    title = `${date.toLocaleString('default', { month: 'long', year: 'numeric'})} Species for ${location}`
                } else if (year !== 'life') {
                    title = `${year} Species for ${location}`
                }
            }

            setTitle(title);
        }
    }, [observations]);

    return (
        <>
            {title ?
                <h2 className="infobox__title">
                    {title}
                </h2>
                : null
            }

            <div className="infobox__body">
                {observations?.map((obs, i) => (
                    <ObservationCard
                        key={i}
                        image={obs.image}
                        kicker={`${i + 1}. ${obs.commonName}`}
                        copy={
                            <>
                                {formatDate(obs.date)}<br/>
                                {obs.time},
                                Count: {obs.count}
                            </>
                        }
                        icon={getIcon(obs)}
                    />
                ))}
            </div>
        </>
    )
}