import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import './map-view.scss';
import turf from 'turf';
import Infobox from "../components/infobox";
import {PlayControls} from "../components/play-controls";
import {InfoboxSingleObservation} from "../components/infobox-single-observation";
import {MapMode, MapModeType} from "../App";
import {InfoboxSpeciesLocationDetail} from "../components/infobox-species-location-detail";
import {InfoboxLocationDetail} from "../components/infobox-location-detail";
import {EBirdMyDataSchema, EBirdObservationsByLocation, getFilteredObservations} from "ebird-mydata-reader";
import {EBirdMyDataSchemaWithImage} from "./big-year";

export type MapViewProps = {
    mode: MapModeType
    markers: any,
    year?: string|number,
    month?: string|number,
    showPlayControls?: boolean,
    activeSpecies: number,
    activeLocation: string,
    setActiveLocation: React.Dispatch<string|undefined>,
    observationsByLocation: EBirdObservationsByLocation[],
    setObservationImages: (observations: EBirdMyDataSchema[]) => EBirdMyDataSchemaWithImage[]
}

export default function MapView({
    mode,
    markers,
    year,
    month,
    showPlayControls,
    activeSpecies,
    activeLocation,
    setActiveLocation,
    observationsByLocation,
    setObservationImages
}: MapViewProps) {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib2hpb2RhdmUiLCJhIjoiY2swMDZqdWw1MjVxeDNqcW9uaG8xcW9nbyJ9.Hlqy7TBoh8QIPWC5j7AQNQ';
    const ANIMATION_DELAY = 4500;
    const SHORT_DELAY = 2500;
    const EXTRA_SHORT_DELAY = 500;
    const STARTING_ZOOM = 13;
    const ZOOM_INCREMENT = 0;
    const ZOOMED_OUT_THRESHOLD = 7;

    const mapRoot = useRef<any>(null);
    const map = useRef<any>(null);
    const miniMapRoot = useRef<any>(null);
    const miniMap = useRef<any>(null);
    const markersRoot = useRef<any>();
    const markerTemplate = useRef<any>();

    let lastMarker: any = useRef(null);
    let currentMarker: any = useRef(null);
    let currentIndex: any = useRef(-1);

    const [currentMarkerState, setCurrentMarkerState]: any = useState();
    const [currentIndexState, setCurrentIndexState]: any = useState();
    const [totalMarkers, setTotalMarkers]: any = useState();
    const [paused, setPaused] = useState(false);
    const [lastZoom, setLastZoom] = useState(STARTING_ZOOM);
    const [activeLocationObservations, setActiveLocationObservations] = useState<any[]>();

    let mapTimeout: any = useRef(null);
    let activeMarkers: React.MutableRefObject<any[]> = useRef([]);

    useEffect(() => {
        if (map.current) { return; }

        map.current = new mapboxgl.Map({
            container: mapRoot.current,
            style: 'mapbox://styles/mapbox/outdoors-v11',
            /*projection: {
              name: 'globe'
            },*/
            center: [-98.5795, 39.8283],
            pitch: 25,
            zoom: 4,
            maxZoom: 14,
            keyboard: false,
            attributionControl: false
        });

        updateZoomProps();
        map.current.on('zoom', updateZoomProps);

        miniMap.current = new mapboxgl.Map({
            container: miniMapRoot.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [-98.5795, 39.8283],
            zoom: 1,
            interactive: false,
            attributionControl: false
        });

        initMiniMapFollow();

        document.body.addEventListener('keydown', handleKeyPress);

        return () => {
            stop();
            reset();
        }
    }, []);

    const handleKeyPress = (e: KeyboardEvent) => {
        // console.log(e);
        if (mode === MapMode.Year) {
            switch (e.code) {
                case 'ArrowLeft':
                    e.shiftKey ? toStart() : rewind();
                    break;
                case 'ArrowRight':
                    e.shiftKey ? toEnd() : fastForward();
                    break;
                case 'Space':
                    togglePaused();
                    break;
            }
        }
    }

    const updateZoomProps = () => {
        mapRoot.current.className = `map-root zoom-${parseInt(map.current.getZoom())}`

        if (map.current.getZoom() < ZOOMED_OUT_THRESHOLD) {
            miniMapRoot.current.className = 'minimap-root state-hidden';
        } else {
            miniMapRoot.current.className = 'minimap-root';
        }
    }

    const initMiniMapFollow = () => {
        updateMiniMap();
        map.current.on('move', updateMiniMap);
    }

    const updateMiniMap = () => {
        miniMap.current.jumpTo({
            center: map.current.getCenter(),
            zoom: 4.5,
            speed: 0.5
        });
    }

    const handleMarkerClick = (locationId: string) => {
        console.log(locationId);
        console.log(mode);

        // mode = MapMode.Location;

        const foundMarker = markers.features.find((el: any) => el.properties.locationId === locationId);
        setActiveLocation(locationId);

        if (foundMarker) {
            currentMarker.current = foundMarker;
            setCurrentMarkerState(foundMarker);
            updateMarkerStates();
        }
    }

    useEffect(() => {
        // console.log(markers);
        setTotalMarkers(markers?.features?.length ?? 0);

        stop();
        reset();

        if (markers?.features?.length) {
            addAllMarkers();
            if (mode === MapMode.Year) {
                playNext();
            } else {
                done();
            }
        }
    }, [markers, mode]);

    useEffect(() => {
        setActiveLocation(undefined);
    }, [mode]);

    useEffect(() => {
        if (activeLocation) {
            if (mode === MapMode.Species && activeSpecies) {
                const filteredFeatures = markers.features.filter((el: any) => el.properties.locationId === activeLocation);
                setActiveLocationObservations(filteredFeatures);
            } else if (activeLocation) {
                setPaused(true);
                stop();

                const foundLocation = observationsByLocation.find(el => el.locationId === activeLocation);
                if (foundLocation) {
                    let filteredObservations = getFilteredObservations(foundLocation.observations, parseInt(year as string), parseInt(month as string), true);
                    filteredObservations = setObservationImages(filteredObservations);
                    setActiveLocationObservations(filteredObservations);
                }
            }
        }
    }, [activeLocation]);

    const reset = () => {
        currentIndex.current = -1;
        setCurrentIndexState(-1);

        currentMarker.current = null;
        setCurrentMarkerState(null);

        lastMarker.current = null;

        for (const marker of activeMarkers.current) {
            marker.domElement.removeEventListener('click', marker.eventListener);
            marker.marker.remove();
            // marker.domElement.parentElement.removeChild(marker.domElement);
        }

        activeMarkers.current = [];

        setActiveLocation(undefined);

    }

    const stop = () => {
        map.current.stop();
        miniMap.current.stop();
        clearTimeout(mapTimeout.current);
    }

    const playNext = () => {
        currentIndex.current = currentIndex.current + 1;
        currentMarker.current = markers.features[currentIndex.current];
        if (!currentMarker.current) return;

        setActiveLocation(undefined);
        setCurrentIndexState(currentIndex.current);
        setCurrentMarkerState(currentMarker.current);

        updateMarkerStates();

        let zoomLevel, speed;
        if (lastMarker.current?.properties.locationName === currentMarker.current?.properties.locationName) {
            const zl = lastZoom + ZOOM_INCREMENT;
            setLastZoom(zl);
            zoomLevel = zl;
            speed = 0.5;
        } else {
            const zl = STARTING_ZOOM;
            setLastZoom(zl);
            zoomLevel = STARTING_ZOOM;
            speed = 1.5;
        }

        map.current.flyTo({
            center: currentMarker.current.geometry.coordinates,
            zoom: zoomLevel,
            curve: 2,
            speed: speed
        });

        map.current.once('moveend', () => {
            // Duration the slide is on screen after interaction
            let delay = !movedSinceLastMarker() ? SHORT_DELAY : ANIMATION_DELAY;

            if (currentIndex.current >= markers.features.length - 1) {
                mapTimeout.current = setTimeout( () => {
                    done();
                }, delay);
            } else {
                clearTimeout(mapTimeout.current);
                mapTimeout.current = setTimeout( () => {
                    // Increment index
                    lastMarker.current =currentMarker.current;
                    playNext();
                }, delay);
            }

        });
    }

    const movedSinceLastMarker = () => {
        return (lastMarker.current?.properties?.locationName !== currentMarker.current?.properties?.locationName);
    }

    const togglePaused = () => {
        setPaused(!paused);
        if (paused) {
            clearTimeout(mapTimeout.current);
            mapTimeout.current = setTimeout(() => {
                playNext();
            }, EXTRA_SHORT_DELAY);
        } else {
            stop();
        }
    }

    const toStart = () => {
        currentIndex.current = -1;
        stop();
        setPaused(false);
        playNext();
    }

    const rewind = () => {
        currentIndex.current = Math.max( currentIndex.current - 2, -1);

        stop();
        setPaused(false);
        playNext();
    }

    const fastForward = () => {
        if (currentIndex.current >= markers?.features?.length - 1) { return; }

        stop();
        setPaused(false)
        playNext();
    }

    const toEnd = () => {
        currentIndex.current = Math.max(markers?.features?.length - 2, -1);

        stop();
        setPaused(false);
        playNext();
    }

    const done = () => {
        lastMarker.current = null;

        try {
            const bounds = turf.bbox(markers);
            map.current.fitBounds(bounds, { padding: 150 });
        } catch(e) {
            console.log('Unable to zoom to bounds');
        }
    }

    const addAllMarkers = () => {
        let i = 0;
        for (const marker of markers?.features) {
            if (!activeMarkers.current.find(el => el.location === marker.properties.locationId)) {
                addMarker(marker, i++);
            }
        }
    }

    const addMarker = (marker: any, index: number) => {
        if (!markerTemplate.current) return;

        const markerEl: HTMLElement = markerTemplate.current.cloneNode(true);
        markerEl.id = `marker-pin-${index}`;
        markerEl.dataset.locationId = marker.properties.locationId;
        markersRoot.current.appendChild(markerEl);

        const mapMarker = new mapboxgl.Marker({
            element: markerEl,
            // offset: [0, -25]
        }).setLngLat(marker.geometry.coordinates).addTo(map.current);

        const eventListener = markerEl.addEventListener('click', (e) => {
            const target = (e.target as HTMLElement).closest('.map-marker') as HTMLElement;
            handleMarkerClick(target.dataset.locationId as string);
        });

        activeMarkers.current.push({
            location: marker.properties.locationId,
            marker: mapMarker,
            domElement: markerEl,
            eventListener: eventListener
        });
    }

    const updateMarkerStates = () => {
        document.querySelectorAll('.map-marker').forEach(el => {
            el.classList.remove('state-active');
        });

        const activeMarker = activeMarkers.current.find(el => el.location === currentMarker.current.properties.locationId);
        if (activeMarker) {
            activeMarker.domElement.classList.add('state-active');
        }
    }

    return (
        <>
            <div className="markers" id="markers" ref={markersRoot}>
                <svg className="map-marker" ref={markerTemplate} xmlns="http://www.w3.org/2000/svg" width="64.031"
                     height="88.02" viewBox="0 0 64.031 88.02">
                    <path id="location-pin-solid"
                          d="M47.811,5.452c0,2.482-14.567,6.9-20.955,8.724a13.524,13.524,0,0,1-5.9,0C14.455,12.353,0,7.934,0,5.452,0,2.441,10.7,0,23.906,0S47.811,2.441,47.811,5.452Z"
                          transform="translate(8.185 73.518)" fill="rgba(0,0,0,0.5)"/>
                    <path id="location-pin-solid-2" className="map-marker__bg" data-name="location-pin-solid"
                          d="M64.031,32.016c0,14.574-19.51,40.52-28.064,51.225a5.038,5.038,0,0,1-7.9,0C19.359,72.535,0,46.589,0,32.016a32.016,32.016,0,1,1,64.031,0Z"
                          fill="#51a488"/>
                    <path id="crow-solid"
                          d="M28.858,1.854h2.76A3.684,3.684,0,0,1,35.038,4.8l.215.872L29.965,7.1v4.293a11.414,11.414,0,0,1-7.7,11.072l2.313,6.087a1.476,1.476,0,0,1-.722,1.866,1.291,1.291,0,0,1-1.724-.781l-2.567-6.8c-.061,0-.116.054-.176.054H17.136l2.154,5.658a1.476,1.476,0,0,1-.722,1.866,1.291,1.291,0,0,1-1.724-.781l-2.567-6.743H6.946L2.837,26.317a1.679,1.679,0,0,1-2.472-.352,2.017,2.017,0,0,1,.322-2.677l19.583-16.3V5.247A5.059,5.059,0,0,1,25.118,0a4.713,4.713,0,0,1,3.74,1.908Zm-3.74,4.77A1.344,1.344,0,0,0,26.44,5.193a1.326,1.326,0,1,0-2.644,0A1.344,1.344,0,0,0,25.118,6.624Z"
                          transform="translate(12.5 19)" fill="#fff"/>
                </svg>
            </div>
            <div className="map-root" ref={mapRoot}></div>
            <div className="minimap-root" ref={miniMapRoot}></div>
            {mode === MapMode.Year || activeLocation ?
                <Infobox>
                    {
                        (mode === MapMode.Year && !activeLocation) ?
                            <InfoboxSingleObservation
                                currentMarker={currentMarkerState}
                                currentIndex={currentIndexState}
                                total={totalMarkers}
                                year={year}
                                month={month}
                            /> : null
                    }
                    {
                        mode === MapMode.Species && activeSpecies && activeLocation ?
                            <InfoboxSpeciesLocationDetail
                                taxonomicOrder={activeSpecies}
                                observations={activeLocationObservations}
                            />
                            : null
                    }
                    {
                        (mode === MapMode.Location || mode === MapMode.Year) && activeLocation ?
                            <InfoboxLocationDetail
                                observations={activeLocationObservations}
                                year={year}
                                month={month}
                            /> : null
                    }
                </Infobox>
            : null}
            {
                showPlayControls ?
                    <PlayControls
                        paused={paused}
                        togglePaused={togglePaused}
                        rewind={rewind}
                        fastForward={fastForward}
                        toStart={toStart}
                        toEnd={toEnd}
                    />
                : null
            }

        </>
    )
}